import React from 'react';
import './FeaturesSection.css';
import { 
  DownloadOutlined, 
  CreditCardOutlined, 
  ScanOutlined, 
  CustomerServiceOutlined, 
  SafetyOutlined, 
  RocketOutlined 
} from '@ant-design/icons';

function FeaturesSection() {
  return (
    <div className="features-section">
      <div className="feature-item">
        <DownloadOutlined className="feature-icon" />
        <h3>INSTANT TICKET DELIVERY</h3>
        <p>Receive your tickets immediately after purchase—no waiting required.</p>
      </div>
      <div className="divider"></div>
      <div className="feature-item">
        <CreditCardOutlined className="feature-icon" />
        <h3>SECURE CHECKOUT</h3>
        <p>Pay safely using trusted payment methods, including major credit cards.</p>
      </div>
      <div className="divider"></div>
      <div className="feature-item">
        <ScanOutlined className="feature-icon" />
        <h3>GO PAPERLESS</h3>
        <p>Access your tickets digitally and reduce waste with easy scanning.</p>
      </div>
      <div className="divider"></div>
      <div className="feature-item">
        <CustomerServiceOutlined className="feature-icon" />
        <h3>24/7 SUPPORT</h3>
        <p>We’re here for you anytime—just reach out to our support team.</p>
      </div>
      <div className="divider"></div>
      <div className="feature-item">
        <SafetyOutlined className="feature-icon" />
        <h3>TRUSTED PLATFORM</h3>
        <p>Your security is our priority. Buy tickets with confidence.</p>
      </div>
      <div className="divider"></div>
      <div className="feature-item">
        <RocketOutlined className="feature-icon" />
        <h3>FAST BOOKING</h3>
        <p>Book your tickets in seconds with our optimized and intuitive platform.</p>
      </div>
    </div>
  );
}

export default FeaturesSection;
