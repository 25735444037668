import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  LineChart,
  Line,
} from 'recharts';
import './OrderVisuals.css';

const OrderVisuals = () => {
  const location = useLocation();
  const orders = location.state?.orders ?? [];
  const [selectedEvent, setSelectedEvent] = useState('All Events');

  const eventNames = [
    ...new Set(
      orders.flatMap((order) =>
        Array.isArray(order.tickets)
          ? order.tickets
              .map((ticket) => ticket.eventName) // Extract event names
              .filter((eventName) => eventName && eventName.trim() !== '') // Filter out empty/undefined/null values
          : []
      )
    ),
  ];

  const filteredOrders = orders.filter(
    (order) =>
      selectedEvent === 'All Events' ||
      (Array.isArray(order.tickets) &&
        order.tickets.some((ticket) => ticket.eventName === selectedEvent))
  );

  const totalRevenue = filteredOrders.reduce(
    (sum, order) => sum + (order.total || 0),
    0
  );
  const totalTickets = filteredOrders.reduce(
    (total, order) =>
      total + (Array.isArray(order.tickets) ? order.tickets.length : 0),
    0
  );

  const eventTicketData = eventNames.map((eventName) => ({
    name: eventName,
    tickets: filteredOrders.reduce(
      (sum, order) =>
        sum +
        (Array.isArray(order.tickets)
          ? order.tickets.filter((ticket) => ticket.eventName === eventName)
              .length
          : 0),
      0
    ),
  }));

  // Debugging: Log the orders and filteredOrders
  console.log('Orders:', orders);
  console.log('Filtered Orders:', filteredOrders);

  const dateTicketData = filteredOrders
    .filter((order) => order.createdAt) // Ensure createdAt exists
    .map((order) => {
      let date;

      // Handle Firestore Timestamp
      if (typeof order.createdAt === 'object' && order.createdAt.seconds) {
        date = new Date(order.createdAt.seconds * 1000)
          .toISOString()
          .split('T')[0];
      }
      // Handle ISO Date String (e.g., "2023-03-09T10:37:00Z")
      else if (
        typeof order.createdAt === 'string' &&
        order.createdAt.includes('T')
      ) {
        date = new Date(order.createdAt).toISOString().split('T')[0];
      }
      // Handle Custom Date String (e.g., "March 9, 2023")
      else if (typeof order.createdAt === 'string') {
        const parsedDate = new Date(order.createdAt);
        if (!isNaN(parsedDate)) {
          date = parsedDate.toISOString().split('T')[0];
        } else {
          console.warn('Invalid date format:', order.createdAt);
          return null; // Skip invalid dates
        }
      }
      // Handle other cases (e.g., invalid or missing data)
      else {
        console.warn('Unsupported date format:', order.createdAt);
        return null; // Skip unsupported formats
      }

      return {
        date,
        ticketsSold: Array.isArray(order.tickets)
          ? order.tickets.reduce((sum, t) => sum + (t.quantity || 1), 0)
          : 0,
        revenue: order.total || 0,
      };
    })
    .filter((item) => item !== null) // Remove null entries
    .reduce((acc, item) => {
      let existing = acc.find((entry) => entry.date === item.date);
      if (existing) {
        existing.ticketsSold += item.ticketsSold;
        existing.revenue += item.revenue;
      } else {
        acc.push(item);
      }
      return acc;
    }, [])
    .sort((a, b) => new Date(a.date) - new Date(b.date)); // Ensure sorted by date

  // Debugging: Log the dateTicketData
  console.log('Date Ticket Data:', dateTicketData);

  return (
    <div className="order-visuals-container">
      <h1 className="title">Orders Visualization</h1>
      <div className="filter-container">
        <label htmlFor="eventFilter" className="filter-label">
          Filter by Event:
        </label>
        <select
          id="eventFilter"
          className="event-filter"
          value={selectedEvent}
          onChange={(e) => setSelectedEvent(e.target.value)}
        >
          <option value="All Events">All Events</option>
          {eventNames.map((eventName, index) => (
            <option key={index} value={eventName}>
              {eventName}
            </option>
          ))}
        </select>
      </div>

      <div className="stats-container">
        <div className="stat-card">
          <h2>Total Revenue</h2>
          <p>${totalRevenue.toFixed(2)}</p>
        </div>
        <div className="stat-card">
          <h2>Total Orders</h2>
          <p>{filteredOrders.length}</p>
        </div>
        <div className="stat-card">
          <h2>Total Tickets Sold</h2>
          <p>{totalTickets}</p>
        </div>
      </div>

      <div className="charts-container">
        <div className="chart-box">
          <h2>Tickets Sold & Revenue Over Time</h2>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart
              data={
                dateTicketData.length > 0
                  ? dateTicketData
                  : [{ date: 'No Data', ticketsSold: 0, revenue: 0 }]
              }
            >
              <XAxis dataKey="date" stroke="#ffffff" />
              <YAxis
                yAxisId="left"
                stroke="#FF6384"
                label={{
                  value: 'Tickets Sold',
                  angle: -90,
                  position: 'insideLeft',
                }}
              />
              <YAxis
                yAxisId="right"
                orientation="right"
                stroke="#36A2EB"
                label={{
                  value: 'Revenue ($)',
                  angle: -90,
                  position: 'insideRight',
                }}
              />
              <Tooltip
                contentStyle={{ backgroundColor: '#333', color: '#fff' }}
              />
              <Legend />
              <Line
                yAxisId="left"
                type="monotone"
                dataKey="ticketsSold"
                stroke="#FF6384"
                strokeWidth={2}
              />
              <Line
                yAxisId="right"
                type="monotone"
                dataKey="revenue"
                stroke="#36A2EB"
                strokeWidth={2}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>

        <div className="chart-box">
          <h2>Tickets Sold Per Event</h2>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={eventTicketData}>
              <XAxis dataKey="name" stroke="#ffffff" />
              <YAxis stroke="#ffffff" />
              <Tooltip
                contentStyle={{ backgroundColor: '#333', color: '#fff' }}
              />
              <Legend />
              <Bar dataKey="tickets" fill="#4F46E5" />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className="chart-box">
          <h2>Revenue Breakdown</h2>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={eventTicketData}
                dataKey="tickets"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={100}
              >
                {eventTicketData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={['#FF6384', '#36A2EB', '#FFCE56'][index % 3]}
                  />
                ))}
              </Pie>
              <Tooltip
                contentStyle={{ backgroundColor: '#333', color: '#fff' }}
              />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>

      <h2 className="table-title">Orders Table</h2>
      <div className="table-container">
        <table className="orders-table">
          <thead>
            <tr>
              <th>Order Number</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Address</th>
              <th>Total ($)</th>
              <th>Tickets</th>
              <th>Order Date</th>
            </tr>
          </thead>
          <tbody>
            {filteredOrders.length > 0 ? (
              filteredOrders.map((order) => (
                <tr key={order.id}>
                  <td>{order.orderNumber}</td>
                  <td>{order.name}</td>
                  <td>{order.email}</td>
                  <td>{order.phone || 'N/A'}</td>
                  <td>
                    {order.address?.line1}, {order.address?.city},{' '}
                    {order.address?.state}, {order.address?.postal_code},{' '}
                    {order.address?.country}
                  </td>
                  <td>${order.total.toFixed(2)}</td>
                  <td>
                    {Array.isArray(order.tickets) &&
                    order.tickets.length > 0 ? (
                      <ul className="ticket-list">
                        {order.tickets.map((ticket, index) => (
                          <li key={index}>
                            {ticket.eventName} ({ticket.ticketType}) - x
                            {ticket.quantity ?? 1}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      'No tickets'
                    )}
                  </td>
                  <td>
                    {order.createdAt
                      ? typeof order.createdAt === 'object' &&
                        order.createdAt.seconds
                        ? `${order.createdAt.seconds}` // Firestore Timestamp (seconds)
                        : order.createdAt // Regular string or other format
                      : 'N/A'}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8" className="no-orders">
                  No orders available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="button-container">
        <button className="back-button" onClick={() => window.history.back()}>
          Go Back
        </button>
      </div>
    </div>
  );
};

export default OrderVisuals;
