import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, addDoc, getDocs } from 'firebase/firestore';
import { db, storage } from './firebase'; // Make sure storage is exported
import { PDFDocument, rgb } from 'pdf-lib';
import QRCode from 'qrcode';
import logo from './assets/logo.png';

// ✅ Helper Function to Center Text
const centerText = (text = '', fontSize = 14, pageWidth = 400) => {
  text = text || '';
  if (typeof text !== 'string') text = String(text);
  const textWidth = text.length * (fontSize * 0.5);
  return (pageWidth - textWidth) / 2;
};

export const generateTicketPDF = async (orderDetails) => {
  const {
    orderNumber = 'N/A',
    customerName = 'Guest',
    eventName = 'Unknown Event',
    ticketNumber = '0000',
    ticketType = 'General Admission',
    eventLocation = { location: 'Venue TBD', datetime: 'Date & Time TBD' },
  } = orderDetails;

  const pdfDoc = await PDFDocument.create();
  const page = pdfDoc.addPage([400, 600]);
  const { width, height } = page.getSize();

  const safeEventName = eventName ? String(eventName) : 'Event Name TBD';
  const safeCustomerName = customerName ? String(customerName) : 'Guest';
  const safeTicketType = ticketType ? String(ticketType) : 'General Admission';
  const safeTicketNumber = ticketNumber ? String(ticketNumber) : '0000';

  // ✅ Extracting location and datetime from the eventLocation object
  const safeEventLocation =
    typeof eventLocation === 'object'
      ? eventLocation.location || 'Venue TBD'
      : eventLocation || 'Venue TBD';
  const safeDatetime = orderDetails.datetime || 'Date & Time TBD';

  page.drawText(safeEventName, {
    x: centerText(safeEventName, 18, width),
    y: height - 50,
    size: 18,
    color: rgb(0, 0, 0),
  });

  page.drawText('Location:', {
    x: centerText('Location:', 14, width),
    y: height - 80,
    size: 14,
    color: rgb(0, 0, 0),
  });
  page.drawText(safeEventLocation, {
    x: centerText(safeEventLocation, 14, width),
    y: height - 100,
    size: 14,
    color: rgb(0, 0, 0),
  });

  page.drawText('Date & Time:', {
    x: centerText('Date & Time:', 14, width),
    y: height - 130,
    size: 14,
    color: rgb(0, 0, 0),
  });
  page.drawText(safeDatetime, {
    x: centerText(safeDatetime, 14, width),
    y: height - 150,
    size: 14,
    color: rgb(0, 0, 0),
  });

  page.drawText(safeCustomerName, {
    x: centerText(safeCustomerName, 14, width),
    y: height - 180,
    size: 14,
    color: rgb(0, 0, 0),
  });

  page.drawText(`Ticket Type: ${safeTicketType}`, {
    x: centerText(`Ticket Type: ${safeTicketType}`, 14, width),
    y: height - 210,
    size: 14,
    color: rgb(0, 0, 0),
  });

  page.drawText(`Ticket #: ${safeTicketNumber}`, {
    x: centerText(`Ticket #: ${safeTicketNumber}`, 14, width),
    y: height / 2 - 90, // ✅ Adjusted position below the QR code
    size: 14,
    color: rgb(0, 0, 0), // ✅ Ensure the text is black
  });

  const qrDataURL = await QRCode.toDataURL(safeTicketNumber, {
    errorCorrectionLevel: 'H',
  });
  const qrImageBytes = await fetch(qrDataURL).then((res) => res.arrayBuffer());
  const qrImage = await pdfDoc.embedPng(qrImageBytes);

  const logoBytes = await fetch(logo).then((res) => res.arrayBuffer());
  const logoImage = await pdfDoc.embedPng(logoBytes);

  page.drawImage(qrImage, {
    x: width / 2 - 75,
    y: height / 2 - 75,
    width: 150,
    height: 150,
  });

  page.drawImage(logoImage, {
    x: width / 2 - 15,
    y: height / 2 - 15,
    width: 30,
    height: 30,
  });

  page.drawText(`© 2025 Black Canyon Tickets`, {
    x: centerText(`© 2025 Black Canyon Tickets`, 10, width),
    y: 20,
    size: 10,
    color: rgb(0, 0, 0),
  });

  // Add disclosure statement
  const disclosureText =
    'By use of this ticket, the ticket purchaser/holder agrees that: (a) he or she shall not transmit or aid in transmitting any information about the event to which it grants admission, including, but not limited to, any account, description, picture, video, audio, reproduction, or other information concerning the event; and the event organizer and each of its respective agents, shall have the unrestricted right and license to use his or her image, likeness, name, voice, comments, or other proprietary or public rights and that of any minor accompanying the holder in any broadcast, telecast, or photograph and/or video and/or audio sound recording taken in connection with the event or other transmission or reproduction in whole or in part of the event, for all purposes, without compensation. The holder voluntarily assumes all risks incident to the event, including the risk of lost, stolen or damaged property or personal injury. The event organizer may revoke this license and eject or refuse entry to the holder for violation of venue rules, illegal activity, misconduct or failure to comply with any and all security measures (including metal detection and bag checks). Any item(s) determined to be unacceptable by the event organizer will not be permitted. If any prohibited items are revealed, the holder will be permitted to return to their vehicle with the item(s) or they can dispose of the item(s). The event organizer will not store any personal belongings or items of any type. The holder voluntarily assumes all risk associated with the purchase of this ticket from anyone other than the event organizer or its designated agents. This ticket may not be used for advertising, promotion (including contests or sweepstakes), or other trade or commercial purposes without the express written consent of the event organizer.';
  page.drawText(disclosureText, {
    x: 20, // Left-align the text with some padding
    y: 110, // Move the text higher (adjust this value as needed)
    size: 5,
    color: rgb(0.5, 0.5, 0.5), // Grey color
    lineHeight: 7, // Slightly larger line height for better readability
    maxWidth: width - 40, // Set max width to prevent overflow (with padding)
  });

  const pdfBytes = await pdfDoc.save();
  return new Blob([pdfBytes], { type: 'application/pdf' });
};
