import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import MainSection from './components/MainSection';
import FeaturesSection from './components/FeaturesSection';
import EventsSection from './components/EventsSection';
import TestimonialSection from './components/TestimonialSection';
import FAQSection from './components/FAQSection';
import ContactForm from './components/ContactForm';
import Footer from './components/Footer';
import CartPage from './components/CartPage';
import PaymentPage from './components/PaymentPage';
import OrderConfirmation from './components/OrderConfirmation';
import AdminDashboard from './admin/AdminDashboard';
import OrdersPage from './admin/OrdersPage';
import EventsPage from './admin/EventsPage';
import Scanner from './admin/Scanner';
import OrderVisuals from './admin/OrderVisuals';
import Login from './components/Login';
import ProtectedRoute from './components/ProtectedRoute';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions';
import EventDetailPage from './components/EventDetailPage';

function App() {
  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route path="/events/:eventSlug" element={<EventDetailPage />} />

          {/* Public Routes */}
          <Route
            path="/"
            element={
              <>
                <MainSection />
                <FeaturesSection />
                <EventsSection />
                <TestimonialSection />
                <FAQSection />
                <ContactForm />
                <Footer />
              </>
            }
          />
          <Route path="/cart" element={<CartPage />} />
          <Route path="/payment" element={<PaymentPage />} />
          <Route path="/order-confirmation" element={<OrderConfirmation />} />
          <Route path="/login" element={<Login />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />

          {/* Protected Routes - All routes under /admin */}
          <Route element={<ProtectedRoute />}>
            <Route path="/admin" element={<AdminDashboard />} />
            <Route path="/admin/orders" element={<OrdersPage />} />
            <Route path="/admin/events" element={<EventsPage />} />
            <Route path="/admin/scanner" element={<Scanner />} />
            <Route path="/admin/orders-visuals" element={<OrderVisuals />} />
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
