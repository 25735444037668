import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './TermsAndConditions.css';

function TermsAndConditions() {
  return (
    <div className="terms-and-conditions-page">
      <Header /> {/* Include the Header */}
      <div className="terms-and-conditions-content">
        <h1>Terms and Conditions</h1>
        <p>
          By using our services, you agree to these Terms and Conditions. Please
          read them carefully.
        </p>
        <h2>1. Acceptance of Terms</h2>
        <p>
          By accessing or using our website, you agree to be bound by these
          Terms and Conditions.
        </p>
        <h2>2. User Responsibilities</h2>
        <p>
          You are responsible for maintaining the confidentiality of your
          account and password.
        </p>
        <h2>3. Prohibited Activities</h2>
        <p>
          You may not use our services for any illegal or unauthorized purpose.
        </p>
        <h2>4. Limitation of Liability</h2>
        <p>
          We are not liable for any damages arising from your use of our
          services.
        </p>
        <h2>5. Changes to Terms</h2>
        <p>
          We reserve the right to modify these Terms and Conditions at any time.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default TermsAndConditions;
