import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { QRCodeCanvas } from 'qrcode.react';
import { collection, addDoc, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { useCart } from '../CartContext';
import { FaPrint } from 'react-icons/fa';
import './OrderConfirmation.css';
import Footer from './Footer';
import { generateTicketPDF } from '../generateTicket';
import { FaDownload } from 'react-icons/fa';


function OrderConfirmation() {
  const location = useLocation();
  const navigate = useNavigate();
  const { clearCart } = useCart();
  const orderSaved = useRef(false);
  const [eventLocations, setEventLocations] = useState({});


  const { name, email, phone, address, orderNumber, total = 0, tickets = [] } = location.state || {};

  useEffect(() => {
    if (!name || !email || !orderNumber) {
      navigate('/');
      return;
    }

    const formatDate = (date) => {
      return new Date(date).toLocaleString('en-US', {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      });
    };    

    const fetchEventLocations = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'events'));
        const locations = {};
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          locations[data.name] = {
            location: data.location,
            datetime: data.datetime,
          };
        });
        return locations; // ✅ Return data instead of setting state
      } catch (error) {
        console.error('Error fetching event locations:', error);
        return {};
      }
    };
    

    const saveOrderToDatabase = async () => {
      if (orderSaved.current) return;
      orderSaved.current = true;
    
      try {
        // Flatten tickets to generate unique ticket numbers
        let ticketCounter = 1;

        const eventLocationsData = await fetchEventLocations(); 

        const flattenedTickets = tickets.flatMap((ticket) =>
          Array.from({ length: ticket.quantity }).map(() => {
            const eventLocation = eventLocationsData[ticket.eventName]?.location || 'Venue TBD';
            const datetime = eventLocationsData[ticket.eventName]?.datetime || 'Date & Time TBD';
        
            return {
              eventName: ticket.eventName,
              eventLocation,  // ✅ Ensure eventLocation is correctly assigned
              datetime,       // ✅ Ensure datetime is correctly assigned
              ticketType: ticket.ticketType,
              ticketPrice: parseFloat(ticket.price) || 0,
              ticketNumber: `${orderNumber}-${ticketCounter++}`,
            };
          })
        );
        
        

        const totalQuantity = flattenedTickets.length;  // ✅ Total number of tickets
        const formattedDate = formatDate(new Date());
    
        // Save the order with all tickets inside the same document
        await addDoc(collection(db, 'orders'), {
          name,
          email,
          phone,
          address,
          orderNumber,
          total,
          totalQuantity,
          tickets: flattenedTickets,
          createdAt: formattedDate,
        });

        // Send confirmation email with PDF
sendOrderConfirmationEmail({
  name,
  email,
  orderNumber,
  total,
  phone,
  address,
  tickets: flattenedTickets.map(ticket => ({
    ...ticket,
    eventLocation: ticket.eventLocation || 'Venue TBD',
    datetime: ticket.datetime || 'Date & Time TBD',
  })),
});
    
        clearCart();
      } catch (error) {
        console.error('Error saving order to Firestore:', error);
      }
    };
    
    

    saveOrderToDatabase();
  }, [name, email, phone, orderNumber, address, total, tickets, navigate, clearCart]);

  useEffect(() => {
    const fetchEventLocations = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'events'));
        const locations = {};
  
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          locations[data.name] = {
            location: data.location,
            datetime: data.datetime,
          };
          
        });
  
        setEventLocations(locations);  // ✅ Save to state
      } catch (error) {
        console.error('Error fetching event locations:', error);
      }
    };
  
    fetchEventLocations();
  }, []);
  
  const blobToBase64 = (blob) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => resolve(reader.result.split(',')[1]); // Extract Base64 only
    });
  };
  
  const sendOrderConfirmationEmail = async (orderDetails) => {
    try {
      const { name, email, orderNumber, total, phone, address, tickets } = orderDetails;
  
      // Generate PDFs and convert to Base64
      const ticketPDFs = await Promise.all(
        tickets.map(async (ticket) => {
          // Ensure eventLocation and datetime are correctly assigned
          const eventLocation = ticket.eventLocation || 'Venue TBD';
          const datetime = ticket.datetime || 'Date & Time TBD';
      
          const ticketDetails = {
            orderNumber,
            customerName: name,
            eventName: ticket.eventName,
            eventLocation: typeof ticket.eventLocation === 'object' ? ticket.eventLocation.location || 'Venue TBD' : ticket.eventLocation || 'Venue TBD',
            datetime: ticket.datetime || 'Date & Time TBD',
            ticketNumber: ticket.ticketNumber,
            ticketType: ticket.ticketType,
          };
          
      
          const pdfBlob = await generateTicketPDF(ticketDetails);
          const pdfBase64 = await blobToBase64(pdfBlob);
      
          return {
            filename: `BCT_${ticket.ticketNumber}.pdf`,
            content: pdfBase64,
            encoding: 'base64',
          };
        })
      );
      
  
      // Send email request
      const response = await fetch('https://us-central1-black-canyon-tickets-bct.cloudfunctions.net/sendOrderConfirmation', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name,
          email,
          orderNumber,
          total,
          phone,
          address,
          tickets,
          attachments: ticketPDFs, // ✅ Attach PDF files
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to send order confirmation email');
      }
  
      console.log('Order confirmation email sent successfully');
    } catch (error) {
      console.error('Error sending order confirmation email:', error);
    }
  };
  

  if (!name || !email || !orderNumber) {
    return null;
  }

  let ticketCounter = 1;

  const flattenedTickets = tickets.flatMap((ticket) =>
    Array.from({ length: ticket.quantity }).map(() => ({
      eventName: ticket.eventName,
      eventLocation: eventLocations[ticket.eventName],
      datetime: eventLocations[ticket.eventName]?.datetime,
      ticketType: ticket.ticketType,
      ticketPrice: parseFloat(ticket.price) || 0, // Ensure price is numeric
      ticketNumber: `${orderNumber}-${ticketCounter++}`,
    }))
  );

  const groupedTickets = tickets.reduce((acc, ticket) => {
    const key = `${ticket.eventName}-${ticket.ticketType}`;
    const price = parseFloat(ticket.price) || 0; // Ensure price is valid
    const quantity = parseInt(ticket.quantity, 10) || 0; // Ensure quantity is valid

    if (!acc[key]) {
      acc[key] = { ...ticket, total: 0 };
    }
    acc[key].total += price * quantity;
    return acc;
  }, {});

  const handlePrint = () => {
    setTimeout(() => {
      window.print();
    }, 500); // Add a slight delay to ensure proper rendering on mobile
  };
  

  return (
    <div className="order-confirmation">
      <div className="content-wrapper">
      <h1>Thank You for your order, {name}!</h1>
      <p>Your order has been placed successfully.</p>

      <div className="order-summary">
        <h2>Order Summary</h2>
        <p><strong>Order Number:</strong> {orderNumber}</p>
        <p><strong>Total:</strong> ${Number(total).toFixed(2)}</p>
        <p><strong>Email:</strong> {email}</p>
        <p><strong>Phone:</strong> {phone}</p>
        <p>
  <strong>Address:</strong>{' '}
  {address
    ? `${address.line1 || ''}, ${address.city || ''}, ${address.state || ''}, ${address.postal_code || ''}, ${address.country || ''}`
    : 'No address provided'}
</p>


        <div className="price-breakdown">
          <h3>Price Breakdown</h3>
          <table className="price-table">
            <tbody>
              {Object.values(groupedTickets).map((ticket, index) => (
                <tr key={index}>
                  <td>{`${ticket.eventName} (${ticket.ticketType}) x${ticket.quantity}`}</td>
                  <td style={{ textAlign: 'right' }}>${ticket.total.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <p className="price-total">
            <strong>Total:</strong> ${Number(total).toFixed(2)}
          </p>
        </div>
      </div>

      <div className="tickets-section">
        {flattenedTickets.map((ticket, index) => (
          <div className="ticket-card" key={index}>
            <div className="qr-code-container">
              <div className="qr-code-with-logo">
                <QRCodeCanvas
                  id={`qrCode-${index}`}
                  value={ticket.ticketNumber}
                  size={150}
                  level="H"
                />
                <img
                  src={require('../assets/logo.png')}
                  alt="Logo"
                  className="qr-logo"
                />
              </div>
            </div>
            <div className="ticket-info">
              <p>{ticket.eventName}</p>
              <p><strong>Event Location:</strong> {ticket.eventLocation?.location || 'N/A'}</p>
              <p><strong>Date & Time:</strong> {ticket.eventLocation?.datetime || 'TBD'}</p>
              <p><strong>Ticket Type:</strong> {ticket.ticketType}</p>
              <p><strong>Ticket Number:</strong> {ticket.ticketNumber}</p>
            </div>

            <button
  className="download-button"
  onClick={async () => {
    const ticketDetails = {
      orderNumber,
      customerName: name,
      eventName: ticket.eventName,
      eventLocation: ticket.eventLocation,
      datetime: ticket.datetime,
      ticketNumber: ticket.ticketNumber,
      ticketType: ticket.ticketType,
    };

    const pdfBlob = await generateTicketPDF(ticketDetails);
    const url = URL.createObjectURL(pdfBlob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `BCT_${ticket.ticketNumber}.pdf`;
    link.click();
  }}
>
  Download <FaDownload className="download-icon" />
</button>

          </div>
        ))}
      </div>

      <button className="print-button" onClick={handlePrint}>
        <FaPrint className="print-icon" /> Print This Page
      </button>
      </div>

      <Footer />


    </div>
  );
}

export default OrderConfirmation;
