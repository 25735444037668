import React from 'react';
import { Collapse } from 'antd';
import './FAQSection.css';

const { Panel } = Collapse;

function FAQSection() {
  const faqs = [
    {
      question: "What if I don't have a printer or have access to one?",
      answer: "No printer, no problem! Stop by at our ticketing booth at the event and we’ll print out your tickets for you!",
    },
    {
      question: 'I deleted the email of my tickets by accident, what now?',
      answer: 'Contact our support team, and we’ll resend your ticket confirmation to your email.',
    },
    {
      question: `I don't see the "DOWNLOAD" button in my email, what should I do?`,
      answer: 'Please check your spam/junk folder. If it’s still not there, contact support to resend the email.',
    },
    {
      question: `I don't have a printer, can I use my phone?`,
      answer: 'Yes! Simply show the QR code or confirmation email on your phone at the event entrance.',
    },
    {
      question: 'How can I request a refund?',
      answer: 'Refund requests can be made up to 48 hours before the event. Contact our support team for assistance.',
    },
    {
      question: 'How secure is the online ticketing process?',
      answer: 'Our platform uses industry-standard encryption to ensure your data and payments are secure.',
    },
  ];

  return (
    <div className="faq-section">
      <h2>Frequently Asked Questions</h2>
      <div className="faq-container">
        <Collapse accordion>
          {faqs.map((faq, index) => (
            <Panel header={faq.question} key={index}>
              <p>{faq.answer}</p>
            </Panel>
          ))}
        </Collapse>
      </div>
    </div>
  );
}

export default FAQSection;
