import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { Button } from 'antd';
import { FaMapMarkerAlt } from 'react-icons/fa';
import './EventDetailPage.css';
import { doc, getDoc, getDocs, collection } from 'firebase/firestore';
import { Table, message } from 'antd';
import { useCart } from '../CartContext';
import Footer from './Footer';

function EventDetailPage() {
  const { eventSlug } = useParams();
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [addedToCart, setAddedToCart] = useState(null);
  const { addToCart } = useCart();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        // First try to find by slug
        const eventsQuery = await getDocs(collection(db, 'events'));
        const matchingEvent = eventsQuery.docs.find((doc) => {
          const eventName = doc
            .data()
            .name.toLowerCase()
            .replace(/[^a-z0-9]+/g, '_')
            .replace(/(^_+|_+$)/g, '');
          return eventName === eventSlug;
        });

        if (matchingEvent) {
          setEvent({ id: matchingEvent.id, ...matchingEvent.data() });
        }
      } catch (error) {
        console.error('Error fetching event:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchEvent();
  }, [eventSlug]);

  const handleAddToCart = (ticket) => {
    addToCart({
      event: event.name,
      ticketType: ticket.type,
      price: ticket.price,
    });
    setAddedToCart(ticket.type);
    message.success('Added to cart successfully!');
    setTimeout(() => setAddedToCart(null), 2000);
  };

  const columns = [
    {
      title: 'Ticket Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (price) => `$${parseFloat(price || 0).toFixed(2)}`,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Button
          type={addedToCart === record.type ? 'default' : 'primary'}
          onClick={() => handleAddToCart(record)}
          disabled={addedToCart === record.type}
        >
          {addedToCart === record.type ? '✔ Added' : 'Add to Cart'}
        </Button>
      ),
    },
  ];

  if (loading) return <div className="event-detail-loading">Loading...</div>;
  if (!event)
    return <div className="event-detail-not-found">Event not found</div>;

  return (
    <div className="event-page-container">
      <div className="event-detail-container">
        <div className="event-detail-content">
          <img
            src={event.imageURL}
            alt={event.name}
            className="event-detail-image"
          />

          <h1>{event.name}</h1>

          {event.location && (
            <div className="event-detail-location">
              <FaMapMarkerAlt className="location-icon" />
              <span>{event.location}</span>
            </div>
          )}

          {event.datetime && (
            <div className="event-detail-datetime">📅 {event.datetime}</div>
          )}

          <p className="event-detail-description">{event.description}</p>

          {/* Replace the Buy Tickets button with this */}
          {event.tickets && (
            <Table
              dataSource={event.tickets}
              columns={columns}
              pagination={false}
              rowKey="type"
              style={{ marginTop: '24px' }}
            />
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default EventDetailPage;
