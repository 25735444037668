import React, { useState, useEffect } from 'react';
import { collection, getDocs, addDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage'; // 🔥 Import Firebase Storage
import './EventsPage.css';
import Footer from '../components/Footer';
import { deleteDoc, doc } from 'firebase/firestore'; // ✅ Import Firestore delete functions
import { updateDoc } from 'firebase/firestore'; // Import Firestore update function

function EventsPage() {
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [uploading, setUploading] = useState(false);

    const [newEvent, setNewEvent] = useState({
        name: '',
        datetime: '',
        description: '',
        imageURL: '',
        location: '',
        tickets: [{ type: '', price: '' }] // 🔥 Ensure tickets array is present
    });

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'events'));
                const eventList = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setEvents(eventList);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching events:', error);
                setLoading(false);
            }
        };

        fetchEvents();
    }, []);

    const handleDeleteEvent = async (eventId, imageURL) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this event?");
        if (!confirmDelete) return;
    
        try {
            const storage = getStorage();
            let imageDeleted = false; // Track if image deletion was successful
    
            // 🔥 Check if imageURL exists before deleting
            if (imageURL) {
                const imageRef = ref(storage, imageURL);
                try {
                    // Check if image exists before attempting deletion
                    await getDownloadURL(imageRef);
                    await deleteObject(imageRef);
                    console.log("Image deleted from storage.");
                    imageDeleted = true;
                } catch (error) {
                    if (error.code === 'storage/object-not-found') {
                        console.warn("Image not found in storage. Proceeding to delete event document.");
                    } else {
                        throw error; // Re-throw unexpected errors
                    }
                }
            }
    
            // 🔥 Delete event from Firestore, regardless of image deletion status
            try {
                await deleteDoc(doc(db, "events", eventId));
                setEvents((prevEvents) => prevEvents.filter(event => event.id !== eventId));
                console.log("Event document deleted from Firestore.");
                alert("Event deleted successfully!");
            } catch (error) {
                console.error("Error deleting event document from Firestore:", error);
                alert("Failed to delete event document.");
            }
    
        } catch (error) {
            console.error("Error deleting event or image:", error);
            alert("Failed to delete event.");
        }
    };
    
    

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewEvent((prev) => ({ ...prev, [name]: value }));
    };

    const handleTicketChange = (index, field, value) => {
        const updatedTickets = newEvent.tickets.map((ticket, i) =>
            i === index ? { ...ticket, [field]: value } : ticket
        );
        setNewEvent((prev) => ({ ...prev, tickets: updatedTickets }));
    };

    const addTicket = () => {
        setNewEvent((prev) => ({
            ...prev,
            tickets: [...prev.tickets, { type: '', price: '' }]
        }));
    };

    const removeTicket = (index) => {
        const updatedTickets = newEvent.tickets.filter((_, i) => i !== index);
        setNewEvent((prev) => ({ ...prev, tickets: updatedTickets }));
    };

    const [editModalOpen, setEditModalOpen] = useState(false);
    const [eventToEdit, setEventToEdit] = useState(null);
    const [editingImageUploading, setEditingImageUploading] = useState(false);

    const handleImageUploadForEdit = async (e) => {
        const file = e.target.files[0];
        if (!file || !eventToEdit) return;
    
        setEditingImageUploading(true); // Start upload state
    
        try {
            const storage = getStorage();
            const oldImageURL = eventToEdit.imageURL; // Save the old image URL
            const newImageRef = ref(storage, `event_images/${file.name}`);
    
            // 🔥 Upload the new image
            await uploadBytes(newImageRef, file);
            const newImageURL = await getDownloadURL(newImageRef);
    
            // 🔥 Delete the old image if it exists
            if (oldImageURL) {
                const oldImageRef = ref(storage, oldImageURL);
                await deleteObject(oldImageRef);
                console.log("Old image deleted from storage.");
            }
    
            // ✅ Update the event state with the new image URL
            setEventToEdit((prev) => ({ ...prev, imageURL: newImageURL }));
    
            alert("Image updated successfully!");
        } catch (error) {
            console.error("Error updating image:", error);
            alert("Failed to update image.");
        } finally {
            setEditingImageUploading(false);
        }
    };
    

    const handleSaveEdit = async () => {
        if (!eventToEdit) return;
        
        try {
            const eventRef = doc(db, "events", eventToEdit.id);
            await updateDoc(eventRef, {
                name: eventToEdit.name,
                datetime: eventToEdit.datetime,
                location: eventToEdit.location,
                description: eventToEdit.description,
                imageURL: eventToEdit.imageURL, // ✅ Ensure image updates
                tickets: eventToEdit.tickets.length > 0 ? eventToEdit.tickets : [{ type: "Sales Start Soon", price: 0 }] // ✅ Default if empty
            });
    
            // Update UI
            setEvents((prevEvents) =>
                prevEvents.map((event) => (event.id === eventToEdit.id ? eventToEdit : event))
            );
    
            alert("Event updated successfully!");
            setEditModalOpen(false); // Close modal
        } catch (error) {
            console.error("Error updating event:", error);
            alert("Failed to update event.");
        }
    };
    
    

    const openEditModal = (event) => {
        setEventToEdit(event); // Set selected event for editing
        setEditModalOpen(true); // Open the modal
    };

    const handleEditInputChange = (e) => {
        const { name, value } = e.target;
        setEventToEdit((prev) => ({ ...prev, [name]: value }));
    };
    
    const handleEditTicketChange = (index, field, value) => {
        const updatedTickets = [...eventToEdit.tickets];
        updatedTickets[index][field] = value;
        setEventToEdit((prev) => ({ ...prev, tickets: updatedTickets }));
    };    

    const addEditTicket = () => {
        setEventToEdit((prev) => ({
            ...prev,
            tickets: [...prev.tickets, { type: "", price: "" }]
        }));
    };
    
    const removeEditTicket = (index) => {
        const updatedTickets = eventToEdit.tickets.filter((_, i) => i !== index);
        setEventToEdit((prev) => ({ ...prev, tickets: updatedTickets }));
    };
    

    // 🔥 Handle Image Upload to Firebase Storage
    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        setUploading(true); // Start upload state

        try {
            const storage = getStorage();
            const storageRef = ref(storage, `event_images/${file.name}`);

            await uploadBytes(storageRef, file);
            const downloadURL = await getDownloadURL(storageRef);

            setNewEvent((prev) => ({ ...prev, imageURL: downloadURL }));

            alert("Image uploaded successfully!");
        } catch (error) {
            console.error("Error uploading image:", error);
            alert("Failed to upload image.");
        } finally {
            setUploading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!newEvent.imageURL) {
            alert("Please upload an image before submitting.");
            return;
        }

        try {
            const docRef = await addDoc(collection(db, 'events'), newEvent);
            setEvents((prevEvents) => [...prevEvents, { id: docRef.id, ...newEvent }]);
            alert('Event added successfully!');
            setNewEvent({
                name: '',
                datetime: '',
                description: '',
                imageURL: '',
                location: '',
                tickets: [{ type: '', price: '' }] // Reset ticket array
            });
            setShowModal(false);
        } catch (error) {
            console.error('Error adding event:', error);
            alert('Failed to add event.');
        }
    };

    if (loading) {
        return <div className="loading">Loading events...</div>;
    }

    return (
        <div className="events-container">
            <h1 className="events-heading">Events List</h1>

            <div className="events-table-container">
                <table className="events-table">
                    <thead>
                        <tr>
                            <th>Image</th>
                            <th>Event Name</th>
                            <th>Date & Time</th>
                            <th>Location</th>
                            <th>Description</th>
                            <th>Tickets</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {events.map((event) => (
                            <tr key={event.id}>
                                <td>
                                    {event.imageURL ? (
                                        <img src={event.imageURL} alt={event.name} className="event-image" />
                                    ) : (
                                        "No Image Available"
                                    )}
                                </td>
                                <td>{event.name}</td>
                                <td>{event.datetime}</td>
                                <td>{event.location}</td>
                                <td className="event-description">{event.description}</td>
                                <td>
                                    {event.tickets && event.tickets.length > 0 ? (
                                        <table className="inner-table">
                                            <thead>
                                                <tr>
                                                    <th>Type</th>
                                                    <th>Price ($)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {event.tickets.map((ticket, index) => (
                                                    <tr key={index}>
                                                        <td>{ticket.type || "N/A"}</td>
                                                        <td>${parseFloat(ticket.price || 0).toFixed(2)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    ) : (
                                        "No tickets available"
                                    )}
                                </td>
                                <td>
                                <button className="edit-event-button" onClick={() => openEditModal(event)}>✏️ Edit</button>
    <button className="delete-event-button" onClick={() => handleDeleteEvent(event.id, event.imageURL)}>
        ❌ Delete
    </button>
</td>

                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Add Event Button */}
            <button className="add-event-button" onClick={() => setShowModal(true)}>Add Event</button>

            {/* Popup Modal */}
            {showModal && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h2>Add New Event</h2>
                        <form onSubmit={handleSubmit} className="event-form">
                            <input type="text" name="name" placeholder="Event Name" value={newEvent.name} onChange={handleInputChange} required />
                            <input type="text" name="datetime" placeholder="Date & Time" value={newEvent.datetime} onChange={handleInputChange} required />
                            <input type="text" name="location" placeholder="Location" value={newEvent.location} onChange={handleInputChange} required />
                            
                            {/* Image Upload Input */}
                            <input type="file" accept="image/*" onChange={handleImageUpload} required />
                            {uploading && <p>Uploading image...</p>}

                            <textarea name="description" placeholder="Description" value={newEvent.description} onChange={handleInputChange} required />

                            {/* Ticket Inputs */}
                            <h3>Tickets</h3>
                            {newEvent.tickets.map((ticket, index) => (
                                <div key={index} className="ticket-inputs">
                                    <input type="text" placeholder="Type (e.g., Adult, Child)" value={ticket.type} onChange={(e) => handleTicketChange(index, 'type', e.target.value)} required />
                                    <input type="number" placeholder="Price" value={ticket.price} onChange={(e) => handleTicketChange(index, 'price', e.target.value)} required />
                                    {newEvent.tickets.length > 1 && (
                                        <button type="button" onClick={() => removeTicket(index)}>Remove</button>
                                    )}
                                </div>
                            ))}
                            <button type="button" className="add-ticket-button" onClick={addTicket}>➕ Add Ticket Type</button>

                            <div className="modal-buttons">
                                <button type="submit">Add Event</button>
                                <button type="button" onClick={() => setShowModal(false)}>Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            <Footer />


            {editModalOpen && eventToEdit && (
    <div className="modal-overlay">
        <div className="modal-content">
            <h2>Edit Event</h2>
            <form className="event-form">
                <input type="text" name="name" value={eventToEdit.name} onChange={handleEditInputChange} required />
                <input type="text" name="datetime" value={eventToEdit.datetime} onChange={handleEditInputChange} required />
                <input type="text" name="location" value={eventToEdit.location} onChange={handleEditInputChange} required />
                <textarea name="description" value={eventToEdit.description} onChange={handleEditInputChange} required />

                {/* 🔥 Image Upload */}
                <h3>Event Image</h3>
                {eventToEdit.imageURL && (
                    <img src={eventToEdit.imageURL} alt="Event" className="edit-event-image" />
                )}
                <input type="file" accept="image/*" onChange={handleImageUploadForEdit} />
                {editingImageUploading && <p>Uploading new image...</p>}

                {/* Ticket Editing */}
                <h3>Tickets</h3>
                {eventToEdit.tickets.map((ticket, index) => (
                    <div key={index} className="ticket-inputs">
                        <input type="text" placeholder="Type (e.g., Adult, Child)" value={ticket.type} 
                               onChange={(e) => handleEditTicketChange(index, "type", e.target.value)} required />
                        <input type="number" placeholder="Price" value={ticket.price} 
                               onChange={(e) => handleEditTicketChange(index, "price", e.target.value)} required />
                        {eventToEdit.tickets.length > 1 && (
                            <button type="button" className="remove-ticket-button" onClick={() => removeEditTicket(index)}>❌ Remove</button>
                        )}
                    </div>
                ))}
                <button type="button" className="add-ticket-button" onClick={addEditTicket}>➕ Add Ticket Type</button>

                <div className="modal-buttons">
                    <button type="button" onClick={handleSaveEdit}>💾 Save Changes</button>
                    <button type="button" onClick={() => setEditModalOpen(false)}>❌ Cancel</button>
                </div>
            </form>
        </div>
    </div>
)}





        </div>
    );
}

export default EventsPage;
