import React from 'react';
import { Button, Input } from 'antd';
import './ContactForm.css';

function ContactForm() {
  const handleSubmit = (e) => {
    e.preventDefault();
    alert('Message sent successfully!');
  };

  return (
    <div className="contact-form-container">
      <h2>Contact Us</h2>
      <form onSubmit={handleSubmit} className="contact-form">
        <Input
          type="email"
          placeholder="Your Email"
          className="contact-input"
          required
        />
        <Input.TextArea
          placeholder="Your Message"
          rows={5}
          className="contact-textarea"
          required
        />
        <Button type="primary" htmlType="submit" className="contact-button">
          Send
        </Button>
      </form>
    </div>
  );
}

export default ContactForm;
