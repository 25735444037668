import React from 'react';
import { Button } from 'antd';
import { CalendarOutlined, UploadOutlined } from '@ant-design/icons'; // Ant Design icons
import './MainSection.css';
import backgroundImage from '../assets/BKGNDBCT.jpg'; // Background image

function MainSection() {
  return (
    <div
      className="main-section"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        margin: 0, // Ensure no gap at the top
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        color: 'white',
      }}
    >
      <div className="main-content">
        <h1>Online-Ticketing Solutions for Events</h1>
        <p>Any Venue, Any Time. Serving all of the United States</p>
        <div className="buttons">
          {/* Button with Calendar Icon */}
          <Button type="primary" size="large" icon={<CalendarOutlined />}>
            Upcoming Events
          </Button>

          {/* Button with Upload Icon */}
          <Button size="large" icon={<UploadOutlined />}>
            Sell Your Tickets
          </Button>
        </div>
      </div>
    </div>
  );
}

export default MainSection;
