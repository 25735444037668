import React from 'react';
import { useNavigate } from 'react-router-dom';
import './AdminDashboard.css';
import Footer from '../components/Footer';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';
import { generateTicketPDF } from '../generateTicket';

function AdminDashboard() {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await signOut(auth); // Sign out the user
      navigate('/login'); // Redirect to the login page after logout
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  // Function to generate a test ticket
  const handleGenerateTestTicket = async () => {
    const mockOrderDetails = {
      orderNumber: '12345',
      customerName: 'John Doe',
      eventName: 'Test Event',
      ticketNumber: '67890',
      ticketType: 'General Admission',
      eventLocation: {
        location: 'Test Venue',
        datetime: 'October 31, 2023, 7:00 PM',
      },
    };

    try {
      // Generate the PDF
      const pdfBlob = await generateTicketPDF(mockOrderDetails);

      // Create a URL for the PDF blob
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Open the PDF in a new tab
      window.open(pdfUrl, '_blank');

      // Optionally, trigger a download
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.download = `Test_Ticket_${mockOrderDetails.ticketNumber}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error generating test ticket:', error);
    }
  };

  return (
    <div className="admin-dashboard-container">
      {' '}
      {/* ✅ Wrapper for full-page layout */}
      <div className="admin-content">
        {' '}
        {/* ✅ Content section */}
        <button
          className="admin-button"
          onClick={() => navigate('/admin/orders')}
        >
          Orders
        </button>
        <button
          className="admin-button"
          onClick={() => navigate('/admin/events')}
        >
          Events
        </button>
        <button
          className="admin-button"
          onClick={() => navigate('/admin/scanner')}
        >
          Scanner
        </button>
        <button className="admin-button logout-button" onClick={handleLogout}>
          Logout
        </button>
        <button
          className="admin-button"
          onClick={handleGenerateTestTicket} // Add the test ticket button
        >
          Generate Test Ticket
        </button>
      </div>
      <Footer />{' '}
      {/* ✅ Footer placed outside the content, always at the bottom */}
    </div>
  );
}

export default AdminDashboard;
