import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { Button, Modal, Table, message } from 'antd';
import { useCart } from '../CartContext';
import './EventsSection.css';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';

function EventsSection() {
  const [events, setEvents] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [addedToCart, setAddedToCart] = useState(null); // Track which ticket was added
  const { addToCart } = useCart();
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'events'));
        const eventsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEvents(eventsData);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };
    fetchEvents();
  }, []);

  useEffect(() => {
    if (eventId) {
      // First try to find by slug
      const matchingEvent = events.find(
        (event) => createEventSlug(event.name) === eventId
      );

      if (matchingEvent) {
        setSelectedEvent(matchingEvent);
        setIsModalOpen(true);
      } else {
        // Fallback to ID lookup if no slug match
        const fetchEvent = async () => {
          const docRef = doc(db, 'events', eventId);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            setSelectedEvent({ id: docSnap.id, ...docSnap.data() });
            setIsModalOpen(true);
          }
        };
        fetchEvent();
      }
    }
  }, [eventId, events]);

  const showModal = (event) => {
    setSelectedEvent(event);
    setIsModalOpen(true);
    // Remove all URL changes from here
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedEvent(null);
    // Remove the navigate call
  };

  const createEventSlug = (name) => {
    return name
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '_')
      .replace(/(^_+|_+$)/g, '');
  };

  const copyEventLink = () => {
    const slug = createEventSlug(selectedEvent.name);
    const url = `${window.location.origin}/events/${slug}`;
    navigator.clipboard.writeText(url);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
    message.success('Event link copied!');
  };

  const handleAddToCart = (ticket) => {
    addToCart({
      event: selectedEvent.name,
      ticketType: ticket.type,
      price: ticket.price,
    });
    setAddedToCart(ticket.type); // Mark ticket as added
    message.success('Added to cart successfully!'); // Show success message
    setTimeout(() => setAddedToCart(null), 2000); // Reset button state after 2 seconds
  };

  const handleImageClick = (event) => {
    const slug = createEventSlug(event.name);
    navigate(`/events/${slug}`);
    window.scrollTo(0, 0); // Optional: Scroll to top
  };

  const columns = [
    {
      title: 'Ticket Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (price) => `$${parseFloat(price || 0).toFixed(2)}`, // Format price for display
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Button
          type={addedToCart === record.type ? 'default' : 'primary'}
          onClick={() => handleAddToCart(record)}
          disabled={addedToCart === record.type}
        >
          {addedToCart === record.type ? '✔ Added' : 'Add to Cart'}
        </Button>
      ),
    },
  ];

  return (
    <div className="events-section">
      <h2>Upcoming Events</h2>
      <div className="events-grid">
        {events.map((event) => (
          <div key={event.id} className="event-card">
            <img
              src={event.imageURL}
              alt={event.name}
              className="event-image"
              onClick={() => navigate(`/events/${createEventSlug(event.name)}`)}
              style={{ cursor: 'pointer' }}
            />
            <h3>{event.name}</h3>

            {/* ✅ Event Location */}
            {event.location && (
              <div
                className="event-location"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  margin: '10px 0',
                }}
              >
                <FaMapMarkerAlt style={{ marginRight: '6px', color: 'red' }} />
                <span>{event.location}</span>
              </div>
            )}

            {/* ✅ Datetime with Padding */}
            {event.datetime && (
              <div
                className="event-datetime"
                style={{
                  marginTop: '8px',
                  color: '#555',
                  fontSize: '14px',
                  paddingBottom: '8px',
                }}
              >
                📅 {event.datetime}
              </div>
            )}

            {/* ✅ Buy Tickets Button with Padding */}
            <Button
              type="primary"
              style={{ marginTop: '10px', padding: '10px 20px' }} // Added spacing and button padding
              onClick={() => showModal(event)}
            >
              Buy Tickets
            </Button>
          </div>
        ))}
      </div>

      {selectedEvent && (
        <Modal
          title={selectedEvent.name}
          open={isModalOpen}
          onCancel={handleCancel}
          footer={[
            <Button key="share" onClick={copyEventLink}>
              {isCopied ? 'Copied!' : 'Share Event'}
            </Button>,
            <Button key="close" onClick={handleCancel}>
              Close
            </Button>,
          ]}
        >
          <img
            src={selectedEvent.imageURL}
            alt={selectedEvent.name}
            className="event-modal-image"
            style={{ cursor: 'default' }}
          />

          {/* ✅ Centered Location Section */}
          {selectedEvent.location && (
            <div
              className="event-location"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center', // ✅ Center the location
                marginBottom: '10px',
              }}
            >
              <FaMapMarkerAlt style={{ marginRight: '8px', color: 'red' }} />
              <span>{selectedEvent.location}</span>
            </div>
          )}

          {/* ✅ Centered Date & Time */}
          {selectedEvent.datetime && (
            <div
              className="event-datetime"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center', // ✅ Center the date & time
                marginTop: '8px',
                marginBottom: '10px',
                color: '#555',
                fontSize: '14px',
              }}
            >
              📅 {selectedEvent.datetime}
            </div>
          )}

          <p>{selectedEvent.description}</p>

          <Table
            dataSource={selectedEvent.tickets}
            columns={columns}
            pagination={false}
            rowKey="type"
          />
        </Modal>
      )}
    </div>
  );
}

export default EventsSection;
