import React, { createContext, useContext, useState } from 'react';

const CartContext = createContext();

export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);

  const addToCart = (item) => {
    setCart((prevCart) => {
      const existingItem = prevCart.find(
        (cartItem) =>
          cartItem.event === item.event && cartItem.ticketType === item.ticketType
      );
      if (existingItem) {
        return prevCart.map((cartItem) =>
          cartItem === existingItem
            ? { ...cartItem, quantity: cartItem.quantity + 1 }
            : cartItem
        );
      }
      return [
        ...prevCart,
        { ...item, price: parseFloat(item.price) || 0, quantity: 1 }, // Ensure price is passed
      ];
    });
  };

  const clearCart = () => setCart([]);

  const updateQuantity = (item, quantity) => {
    setCart((prevCart) =>
      prevCart.map((cartItem) =>
        cartItem.event === item.event && cartItem.ticketType === item.ticketType
          ? { ...cartItem, quantity }
          : cartItem
      )
    );
  };

  return (
    <CartContext.Provider value={{ cart, addToCart, clearCart, updateQuantity }}>
      {children}
    </CartContext.Provider>
  );
};
