import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';

// Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyDpXpjfQcNO_Lz7OuzINzZJG6pQXFOOLxI',
  authDomain: 'black-canyon-tickets-bct.firebaseapp.com',
  projectId: 'black-canyon-tickets-bct',
  storageBucket: 'black-canyon-tickets-bct.firebasestorage.app',
  messagingSenderId: '523563669839',
  appId: '1:523563669839:web:3ff2ba413d02d906db0d3a',
  measurementId: 'G-MC2XW53LTC',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);
