import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { auth } from '../firebase';

function ProtectedRoute() {
  const user = auth.currentUser; // Check if a user is logged in
  const location = useLocation();

  return user ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location.pathname }} />
  );
}

export default ProtectedRoute;
