import React from 'react';
import { useCart } from '../CartContext';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import './PaymentPage.css';

const stripePromise = loadStripe('pk_live_IdkbpNgg6uh4VSM1j4L4xhiT');

function PaymentPage() {
  const { cart } = useCart();

  return (
    <div className="payment-page">
      <div className="payment-container">
        <h2>Payment</h2>
        <Elements stripe={stripePromise}>
          <CheckoutForm cart={cart} />
        </Elements>
      </div>

      {/* Footer added here */}
      <footer className="admin-footer">
        © 2025 Black Canyon Tickets
      </footer>
    </div>
  );
}

export default PaymentPage;
