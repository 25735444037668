import React, { useEffect, useState, useRef } from 'react';
import { Html5QrcodeScanner } from 'html5-qrcode';
import { collection, getDocs, updateDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import './Scanner.css';

function Scanner() {
  const [scannedTicket, setScannedTicket] = useState(null);
  const [statusMessage, setStatusMessage] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const scannerRef = useRef(null); // ✅ Prevent re-initialization

  useEffect(() => {
    if (!scannerRef.current) {
      const newScanner = new Html5QrcodeScanner(
        'reader',
        {
          fps: 15,
          qrbox: { width: 300, height: 300 },
          aspectRatio: 1.0,
          disableFlip: false
        },
        false
      );

      newScanner.render(handleScanSuccess, handleScanError);
      scannerRef.current = newScanner;
    }
  }, []); // ✅ Only run once on mount

  const handleScanSuccess = async (ticketNumber) => {
    if (!ticketNumber || showDialog || scannedTicket === ticketNumber) return;

    if (scannerRef.current) {
      scannerRef.current.clear();
      scannerRef.current = null;
    }

    stopCamera();
    setScannedTicket(ticketNumber);
    setStatusMessage('Processing ticket...');
    setShowDialog(true);

    let ticketFound = false;
    let ticketAlreadyScanned = false;

    try {
      const ordersRef = collection(db, 'orders');
      const querySnapshot = await getDocs(ordersRef);

      for (const orderDoc of querySnapshot.docs) {
        const orderData = orderDoc.data();

        if (!orderData.tickets || !Array.isArray(orderData.tickets)) {
          continue;
        }

        const updatedTickets = orderData.tickets.map(ticket => {
          if (ticket.ticketNumber === ticketNumber) {
            if (ticket.scanned) {
              ticketAlreadyScanned = true;
            } else {
              ticket.scanned = true;
              ticketFound = true;
            }
          }
          return ticket;
        });

        if (ticketFound || ticketAlreadyScanned) {
          await updateDoc(doc(db, 'orders', orderDoc.id), { tickets: updatedTickets });
          break;
        }
      }

      const finalMessage = ticketAlreadyScanned
        ? '🚨 This ticket has already been scanned! Entry denied.'
        : ticketFound
        ? '✅ Ticket is valid. Entry granted.'
        : '❌ Invalid ticket!';

      setTimeout(() => {
        setStatusMessage(finalMessage);
      }, 1500);
      
    } catch (error) {
      setTimeout(() => {
        setStatusMessage('⚠️ Error checking ticket. Try again.');
      }, 1500);
    }
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
    setScannedTicket(null);
    setStatusMessage('');

    // ✅ Reinitialize scanner when dialog closes
    setTimeout(() => {
      if (!scannerRef.current) {
        scannerRef.current = new Html5QrcodeScanner(
          'reader',
          {
            fps: 15,
            qrbox: { width: 300, height: 300 },
            aspectRatio: 1.0,
            disableFlip: false
          },
          false
        );
        scannerRef.current.render(handleScanSuccess, handleScanError);
      }
    }, 500);
  };

  const stopCamera = () => {
    document.querySelectorAll('video').forEach(video => {
      if (video.srcObject) {
        video.srcObject.getTracks().forEach(track => track.stop());
      }
    });
  };

  const handleScanError = (error) => {
    if (error.name !== 'NotFoundException') return;
  };

  return (
    <div className="scanner-container">
      <h1>Scan Ticket</h1>
      <div id="reader" style={{ display: showDialog ? 'none' : 'block' }}></div>
      {showDialog && (
        <div className="dialog-box">
          <p>{statusMessage}</p>
          <button onClick={handleCloseDialog}>Close</button>
        </div>
      )}
    </div>
  );
}

export default Scanner;
