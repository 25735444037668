import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './PrivacyPolicy.css';

function PrivacyPolicy() {
  return (
    <div className="privacy-policy-page">
      <Header /> {/* Include the Header */}
      <div className="privacy-policy-content">
        <h1>Privacy Policy</h1>
        <p>
          Your privacy is important to us. This Privacy Policy explains how we
          collect, use, and protect your personal information.
        </p>
        <h2>1. Information We Collect</h2>
        <p>
          We may collect personal information such as your name, email address,
          and payment details when you use our services.
        </p>
        <h2>2. How We Use Your Information</h2>
        <p>
          We use your information to process transactions, improve our services,
          and communicate with you.
        </p>
        <h2>3. Data Security</h2>
        <p>
          We implement security measures to protect your data from unauthorized
          access or disclosure.
        </p>
        <h2>4. Changes to This Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. Any changes will
          be posted on this page.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
