import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../CartContext';
import './CartPage.css';

function CartPage() {
  const { cart, clearCart, updateQuantity } = useCart();
  const navigate = useNavigate();



  const calculateTotal = () =>
    cart.reduce((total, item) => {
      const itemPrice = item.price || 0; // Ensure price is numeric
      const itemQuantity = item.quantity || 0; // Ensure quantity is valid
      return total + itemPrice * itemQuantity;
    }, 0);
  

  cart.reduce((total, item) => {
    const itemPrice = parseFloat(item.price) || 0; // Ensure price is numeric
    const itemQuantity = item.quantity || 0;
    return total + itemPrice * itemQuantity;
  }, 0);
  

  const handleProceedToPayment = () => {
    if (cart.length > 0) {
      navigate('/payment');
    } else {
      alert('Your cart is empty!');
    }
  };

  return (
    <div className="cart-page">
      <h2>Your Cart</h2>
      {cart.length === 0 ? (
        <p>Your cart is empty. Go back and add tickets to proceed.</p>
      ) : (
        <>
          <ul className="cart-items">
            {cart.map((item, index) => (
              <li key={index} className="cart-item">
                <span>
                  {item.event} ({item.ticketType})
                </span>
                <div className="cart-controls">
                  <input
                    type="number"
                    min="1"
                    value={item.quantity}
                    onChange={(e) =>
                      updateQuantity(item, Math.max(1, parseInt(e.target.value) || 1))
                    }
                  />
                  <span>${(item.quantity * item.price).toFixed(2)}</span>
                </div>
              </li>
            ))}
          </ul>
          <h3>Total: ${calculateTotal().toFixed(2)}</h3>
          <div className="cart-actions">
            <button className="clear-cart-button" onClick={clearCart}>
              Clear Cart
            </button>
            <button className="proceed-button" onClick={handleProceedToPayment}>
              Proceed to Payment
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default CartPage;
