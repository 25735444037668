import React from 'react';
import { Carousel } from 'antd';
import { UserOutlined } from '@ant-design/icons'; // Import user icon
import './TestimonialSection.css';

function TestimonialSection() {
  const testimonials = [
    {
      text: 'The events are always well-organized, and the atmosphere is amazing. I look forward to them every year!',
      name: 'Emily',
    },
    {
      text: 'The ticketing process was seamless, and I loved that I could get my tickets instantly on my phone.',
      name: 'John',
    },
    {
      text: 'Great event! Everything from the food to the music was top-notch. Definitely recommend attending.',
      name: 'Sophia',
    },
    {
      text: 'I appreciated how secure and easy it was to book tickets online. A fantastic experience overall!',
      name: 'David',
    },
    {
      text: 'The Lorrie Morgan concert was unforgettable. Thank you for organizing such a wonderful event.',
      name: 'Sarah',
    },
    {
      text: 'Being able to scan my ticket at the entrance made everything so much easier. Very impressed!',
      name: 'Michael',
    },
  ];

  return (
    <div className="testimonial-section">
      <Carousel autoplay>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial">
            <p className="testimonial-text">"{testimonial.text}"</p>
            <div className="testimonial-author">
              <div className="author-image">
                <UserOutlined className="person-icon" />
              </div>
              <span className="author-name">{testimonial.name}</span>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default TestimonialSection;
