import React, { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import './CheckoutForm.css';

function CheckoutForm({ cart = [] }) {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState({
    line1: '',
    city: '',
    state: '',
    postal_code: '',
    country: 'US',
  });
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);

  const [orderNumber] = useState(() => Math.floor(10000 + Math.random() * 90000).toString());

  useEffect(() => {
    const cartTotal = cart.reduce((sum, item) => sum + item.price * item.quantity, 0);
    setTotalAmount(cartTotal * 100); // Convert total to cents
  }, [cart]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setLoading(true);

    const cardElement = elements.getElement(CardElement);

    try {
      const response = await fetch(
        'https://us-central1-black-canyon-tickets-bct.cloudfunctions.net/createPaymentIntent',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            amount: totalAmount,
            currency: 'usd',
            customerEmail: email,
            customerName: name,
            customerPhone: phone,
            customerAddress: address,
            description: `BCT Order #${orderNumber}`,
          }),
        }
      );

      const { clientSecret } = await response.json();

      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name,
            email,
            phone,
            address,
          },
        },
      });

      if (result.error) {
        setMessage(result.error.message);
      } else if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {
        navigate('/order-confirmation', {
          state: {
            name,
            email,
            phone,
            address,
            orderNumber,
            total: totalAmount / 100,
            tickets: cart.map((item) => ({
              eventName: item.event,
              ticketType: item.ticketType,
              price: item.price, // Include the price
              quantity: item.quantity,
            })),
          },
        });
      }
    } catch (error) {
      setMessage('An error occurred. Please try again.');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="checkout-form">
      <h3>Total: ${(totalAmount / 100).toFixed(2)}</h3>
      <input
        type="text"
        placeholder="Full Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
      />
      <input
        type="email"
        placeholder="Email Address"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <input
        type="tel"
        placeholder="Phone Number" // Added phone input
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        required
      />
      <input
        type="text"
        placeholder="Address Line 1"
        value={address.line1}
        onChange={(e) => setAddress({ ...address, line1: e.target.value })}
        required
      />
      <input
        type="text"
        placeholder="City"
        value={address.city}
        onChange={(e) => setAddress({ ...address, city: e.target.value })}
        required
      />
      <input
        type="text"
        placeholder="State"
        value={address.state}
        onChange={(e) => setAddress({ ...address, state: e.target.value })}
        required
      />
      <input
        type="text"
        placeholder="Postal Code"
        value={address.postal_code}
        onChange={(e) => setAddress({ ...address, postal_code: e.target.value })}
        required
      />
      <input
        type="text"
        placeholder="Country"
        value={address.country}
        onChange={(e) => setAddress({ ...address, country: e.target.value })}
        required
        disabled
      />
      <div className="card-element-container">
        <CardElement />
      </div>
      <button type="submit" className="pay-now-button" disabled={!stripe || loading}>
        {loading ? 'Processing...' : `Pay Now ($${(totalAmount / 100).toFixed(2)})`}
      </button>
      {message && <div className="payment-message">{message}</div>}
    </form>
  );
}

export default CheckoutForm;
